// src/components/Gallery.js
import React, { useEffect, useState } from 'react';
import nainitalImage from '../images/nainital.jpg';
import mussoorieImage from '../images/mussorrie.jpg';
import choptaImage from '../images/Chopta.jpg';
import harshilImage from '../images/Harshil.jpg';


// const imageStyle = {
//     width: '800px',
//     height: '400px',
//     objectFit: 'cover',
//     margin: '1rem',
//     display: 'flex'
// };

const imageUrls = [
    { src: nainitalImage, alt: 'Nainital Lake' },
    { src: mussoorieImage, alt: 'Mussoorie Hills' },
    { src: choptaImage, alt: 'Rishikesh Ganges' },
    { src: harshilImage, alt: 'Auli Snow' }
];


function Gallery() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section id="gallery" className="gallery">
            <h2>Gallery</h2>
            <div className="image-container">
                <img
                    src={imageUrls[currentImageIndex].src}
                    alt={imageUrls[currentImageIndex].alt}
                    // style={imageStyle}
                />
            </div>
            <div className="dots-container">
                {imageUrls.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${currentImageIndex === index ? 'active' : ''}`}
                    ></span>
                ))}
            </div>
        </section>
    );
}

export default Gallery;
