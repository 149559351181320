// src/components/Home.js
import React from 'react';

function Home() {
    return (
        <section id="home">
            <h2>Welcome to Hillscape Uttarakhand</h2>
            <p>Discover the beauty of Uttarakhand's hill stations with our comprehensive travel guide. From the lush green valleys to the majestic peaks, experience the best of nature and adventure.</p>
            <button className="btn" onClick={() => alert('Welcome to Hillscape Uttarakhand!')}>Click Me!</button>
        </section>
    );
}

export default Home;
