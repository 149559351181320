// src/components/Destinations.js
import React from 'react';

function Destinations() {
    return (
        <section id="destinations">
            <h2>Top Destinations</h2>
            <ul>
                <li><strong>Nainital:</strong> Known for its picturesque lake and vibrant markets.</li>
                <li><strong>Mussoorie:</strong> The Queen of Hills with stunning viewpoints and waterfalls.</li>
                <li><strong>Rishikesh:</strong> A spiritual hub and gateway to the Himalayas.</li>
                <li><strong>Auli:</strong> A haven for skiing and breathtaking mountain views.</li>
            </ul>
        </section>
    );
}

export default Destinations;
