// src/components/TravelTips.js
import React from 'react';

function TravelTips() {
    return (
        <section id="travel-tips">
            <h2>Travel Tips</h2>
            <ul>
                <li>Pack appropriate clothing for both warm days and chilly nights.</li>
                <li>Always carry a first-aid kit and essential medications.</li>
                <li>Respect the local culture and traditions.</li>
                <li>Stay hydrated and be mindful of altitude sickness.</li>
            </ul>
        </section>
    );
}

export default TravelTips;
