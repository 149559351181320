// src/App.js
import React from 'react';
import './App.css';
import Home from './components/Home';
import Destinations from './components/Destinations';
import TravelTips from './components/TravelTips';
import Gallery from './components/Gallery';
import Contact from './components/Contact';

function App() {
    return (
        <div className="App">
            <header>
                <h1>Hillscape Uttarakhand</h1>
                <p>Your Guide to the Best Hill Destinations in Uttarakhand</p>
            </header>
            <nav>
                <a href="#home">Home</a>
                <a href="#destinations">Destinations</a>
                <a href="#travel-tips">Travel Tips</a>
                <a href="#gallery">Gallery</a>
                <a href="#contact">Contact</a>
            </nav>
            <Home />
            <Destinations />
            <TravelTips />
            <Gallery />
            <Contact />
            <footer>
                <p>&copy; 2024 Hillscape Uttarakhand. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
