// src/components/Contact.js
import React from 'react';

function Contact() {
    return (
        <section id="contact">
            <h2>Contact Us</h2>
            <p>Email: <a href="mailto:mnnegi5@gmail.com">mnnegi5@gmail.com</a></p>
            <p>Phone: +91 7409642584</p>
            <div className="social-media">
                <p>Follow us on social media:</p>
                <div className="social-links">
                    <a href="#">Facebook</a>
                    <a href="#">Instagram</a>
                    <a href="#">Twitter</a>
                </div>
            </div>
        </section>
    );
}


export default Contact;
